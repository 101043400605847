<template>
  <div>
    <div ref="navBarDiv">
      <ViewerLiteNavBar/>
    </div>
    <div>
      <div ref="titleDiv">
        <b-badge class="w-100 overflow-hidden" variant="info" show size="sm">
          <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
          <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
          &nbsp;
          {{ title }}
        </b-badge>
      </div>
      <div>
        <ViewerLiteSeries :seriesInfo="seriesInfo" :active="active"/>
      </div>
    </div>
  </div>
</template>

<script>
import ViewerLiteNavBar from './ViewerLiteNavBar.vue'
import ViewerLiteSeries from './ViewerLiteSeries.vue'
import broadcast from '../common/broadcast'
import dicomweb from '../common/dicomweb'
import webServices from '../common/webServices'
import workflow from '../common/workflow'

export default {
  name: 'viewerLite',
  components: {
    ViewerLiteNavBar,
    ViewerLiteSeries
  },
  data() {
    return {
      active: false,
      seriesInfo: []
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.active = true
      vm.updateViewerInfo()
    })
  },
  beforeRouteLeave(to, from, next) {
    var answer = true
    if (this.$store.getters.isReportOpenForEdit(this.$store.state.uid)) {
      answer = window.confirm('Report open in editor may have unsaved changes. Are you sure you want to close viewer?')
    }
    if (answer) {
      this.active = false
      this.seriesInfo = []
      next()
    } else {
      next(false)
    }
  },
  created() {
    // Check if UID provided for this window.
    //
    let hrefParts = window.location.href.split('?')
    this.$log.debug("ViewerLite: "+hrefParts[0])
    if ((hrefParts.length > 1) && (hrefParts[0].endsWith('viewer_lite'))) {
      let uid = decodeURIComponent(hrefParts[1].replace(/^.*uid=/, ''))
      this.$log.debug(`Updating uid to [${uid}]`)
      this.$store.state.uid = uid
      this.$store.state.inViewerWindow = true
    }
  },
  mounted() {
    if (this.inViewerWindow) {
      webServices.stopWorklistRefresh()
      broadcast.channel.onmessage = (event) => {
        this.handleMessage(event)
      }
      window.onbeforeunload = (event) => {
        this.handleWindowBeforeUnload(event)
      }
      window.onresize = (event) => {
        this.handleWindowResize(event)
      }
      window.onunload = (event) => {
        this.handleWindowUnload(event)
      }
      broadcast.postMessage(broadcast.VIEWER_WINDOW_READY_MSG, this.$store.state.uid)
    }
  },
  computed: {
    entry() {
      this.$log.debug(`searching for entry for study_uid=${this.studyUid}`)
      const entry = this.$store.getters.worklistEntryForStudy(this.studyUid)
      if (entry != null) {
        this.$log.debug(`entry found study_uid=${entry.study_uid}`)
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    inViewerWindow() {
      return this.$store.state.inViewerWindow || this.$store.getters.openViewersInWindow
    },
    refreshViewer() {
      return this.$store.state.refreshViewer
    },
    studyUid() {
      return this.$store.state._selectedStudyUid
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    }
  },
  watch: {
    studyUid(newval, oldval) {
      if (newval != oldval) {
        this.$log.debug(`entry has changed study_uid=${newval}`)
        this.$store.commit('changeActiveStudyUid', newval)
        this.updateViewerInfo()
      }
    },
    inViewerWindow(newval/*, oldval*/) {
      // Handle installing listeners if viewer options fetched after this component mounted.
      //
      if (newval) {
        webServices.stopWorklistRefresh()
        broadcast.channel.onmessage = (event) => {
          this.handleMessage(event)
        }
        window.onbeforeunload = (event) => {
          this.handleWindowBeforeUnload(event)
        }
        window.onresize = (event) => {
          this.handleWindowResize(event)
        }
        window.onunload = (event) => {
          this.handleWindowUnload(event)
        }
        broadcast.postMessage(broadcast.VIEWER_WINDOW_READY_MSG, this.$store.state.uid)
      }
    },
    refreshViewer(newval/*, oldval*/) {
      if (newval) {
        this.updateViewerInfo()
      }
      this.$store.commit('changeRefreshViewer', false)
    }
  },
  methods: {
    updateViewerInfo() {
      if (this.active && (this.studyUid.length > 0) && (this.studyUid[0] != 'l')) {
        this.seriesInfo = []
        dicomweb.getSeries(this.entry)
        .then(seriesInfo => {
          this.seriesInfo = seriesInfo
        })
        .catch(err => {
          this.$log.error(err.message)
        })
        webServices.updateStudyUserHistoryNoPromise(this.entry, 'images')
      }
    },
    handleMessage(event) {
      try {
        let message = event.data
        if (message !== undefined) {
          if ((message['payload']['target'] == workflow.TARGET_VIEWER) && (message['payload']['windowUid'] == this.$store.state.uid)) {
            if (message['type'] == broadcast.SELECTED_STUDY_MSG) {
              this.$log.debug(broadcast.SELECTED_STUDY_MSG+' message received')
              if (this.studyUid != message['payload']['study_uid']) {
                if (this.studyUid != '') {
                  let payload = {
                    study_uid: this.studyUid,
                    target: workflow.TARGET_VIEWER,
                    window_uid: this.$store.state.uid
                  }
                  broadcast.postMessage(broadcast.CLOSE_STUDY_MSG, payload)
                }
                this.$store.commit('changeWorklist', [ message['payload'] ])
                this.$store.commit('changeSelectedStudyUid', message['payload']['study_uid'])
              }
            }
            else if (message['type'] == broadcast.WORKLIST_NEXT_PREV_MSG) {
              this.$log.debug(broadcast.WORKLIST_NEXT_PREV_MSG+' message received')
              if (message['payload'] != '') {
                this.$store.commit('changeNextStudyUid', message['payload']['nextStudyUid'])
                this.$store.commit('changePreviousStudyUid', message['payload']['previousStudyUid'])
              }
            }
          }
        }
      }
      catch(error) {
        this.$log.error("Error handling message: "+error.message)
      }
    },
    handleWindowBeforeUnload(/*event*/) {
      if (this.$store.getters.isReportOpenForEdit(this.$store.state.uid)) {
        // Browser may present its own message.
        event.preventDefault();
        return event.returnValue = 'Report open in editor may have unsaved changes. Are you sure you want to close this window?'
      }
    },
    handleWindowResize(/*event*/) {
      broadcast.postMessage(broadcast.VIEWER_WINDOW_RESIZE_MSG, this.$store.state.uid)
    },
    handleWindowUnload(/*event*/) {
      if (this.inViewerWindow) {
        broadcast.postMessage(broadcast.VIEWER_WINDOW_CLOSED_MSG, {
          study_uid: this.entry.study_uid,
          window_uid: this.$store.state.uid
        })
      }
    },
  }
};
</script>
<style scoped>
</style>